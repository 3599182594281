@import '@pushdr/stylekit/scss/stylekit.clinicians.scss';
/* ng-select */
@import '@ng-select/ng-select/themes/default.theme.css';

h3 {
  @apply text-sm font-medium text-bluegrey-800;
}

.ng-input {
  input {
    padding: 0;
    margin: auto;
  }
}

.ng-select {
  &.ng-select-single {
    .ng-select-container {
      height: 48px; /*3rem*/
      border-radius: 4px;
      border: 2px solid $borderColor-bluegrey-200;
    }
  }

  .ng-select-container {
    .ng-value-container {
      padding-left: 16px;
    }
  }
}

.ng-dropdown-panel {
  border: 2px solid $borderColor-bluegrey-200;
  .ng-dropdown-panel-items {
    .ng-option {
      padding: 15px 16px;
    }
  }
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: auto;
}

.ng-select.ng-select--no-margin .ng-select-container {
  margin-top: 0;
}

.ng-invalid.ng-touched > .ng-select.ng-select-single > .ng-select-container {
  @apply border border-error;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 28%;
  left: 12px;
}

.small-tabs-bar {
  @apply flex justify-center -mb-4;
  a {
    @apply mr-3 text-sm border-teal-500 cursor-pointer text-grey-500;
    &.active,
    &:hover {
      color: $textColor-bluegrey-800;
      @apply border-b-4;
    }
  }
}

.show-validation {
  input,
  textarea,
  pushdr-diagnosis {
    &.ng-invalid.ng-touched {
      @apply border-error;
      .ng-select.ng-select-single > .ng-select-container {
        @apply border-error;
      }
    }
    &.ng-untouched,
    &.ng-valid {
      // Use attribute selector to avoid a clash with tailwindcss circular dependencies
      // https://developer.mozilla.org/en-US/docs/Web/CSS/Attribute_selectors
      & + [class~='text-error'] {
        display: none;
      }
    }
    & ~ [class~='text-error'] {
      display: block;
      @apply mt-2;
      & ~ [class~='text-error'] {
        @apply mt-0;
      }
    }
  }
}

.field-wrap {
  @extend .show-validation;
  @apply mb-4;
  label {
    @apply mr-1 text-sm text-grey-700;
  }
  &.required label::after {
    content: '*';
    @apply text-error;
  }
  input[type='text'],
  textarea {
    @extend .pdr-input;
    @apply flex-grow m-0;
  }
  textarea {
    @apply self-stretch;
  }
}

.buttons-spaced {
  @apply flex justify-between w-full p-2 px-8;
}
.buttons-centred {
  @apply flex justify-center w-full p-2;
  button {
    @apply mx-2;
  }
}

table.clinicians-table {
  @apply font-light;
  &.hov-effect tr:hover td {
    @apply bg-grey-100;
  }
  th,
  td {
    @apply pt-1 pb-1 pl-2 pr-2 border-b border-grey-200;
  }
  th {
    @apply mb-1 font-light text-grey-500 text-left;
  }
  td {
    @apply text-bluegrey-800;
  }
}

.ph-add-button {
  @apply flex items-center justify-between text-grey-700 text-sm leading-3;
  &::before {
    content: '+';
    @apply mr-1 text-3xl font-extralight leading-4;
  }
  &:hover {
    @apply text-primary-hover;
  }
}

.actions-menu.nav-item {
  @apply bg-primary text-white;
  &:hover {
    @apply bg-indigo-400;
  }
  &.active {
    @apply bg-secondary;
    &:hover {
      background-color: lighten($backgroundColor-secondary, 10%);
    }
    & > span {
      @apply text-bluegrey-800;
    }
  }
  & > .sub-menu > div {
    a,
    button {
      @apply block mt-1 text-sm font-medium text-center border-2 rounded border-bluegrey-200 text-primary;
      &:hover {
        @apply bg-indigo-100 border-indigo-400;
      }
      &.active {
        @apply bg-secondary text-bluegrey-800 border-none;
      }
    }
    button {
      @extend .btn, .btn-outline;
      @apply py-4 w-full;
    }
  }
}

.pd-box {
  @apply p-5 ml-auto mr-auto align-middle bg-white rounded shadow;
  header {
    @apply mb-8 text-lg font-medium;
  }
  &.modal {
    @apply p-20 pt-16 pb-16;
    text-align: center;
  }
}

html,
body,
main {
  height: 100%;
}

@media (max-width: 1824px) {
  pushdr-root,
  simple-modal-holder {
    zoom: 0.95;
  }
}
@media (max-width: 1728px) {
  pushdr-root,
  simple-modal-holder {
    zoom: 0.9;
  }
}
@media (max-width: 1632px) {
  pushdr-root,
  simple-modal-holder {
    zoom: 0.85;
  }
}
@media (max-width: 1536px) {
  pushdr-root,
  simple-modal-holder {
    zoom: 0.8;
  }
}
@media (max-width: 1440px) {
  pushdr-root,
  simple-modal-holder {
    zoom: 0.75;
  }
}
@media (max-width: 1344px) {
  pushdr-root,
  simple-modal-holder {
    zoom: 0.7;
  }
}

button.btn-primary:disabled,
button.btn-primary[disabled] {
  @apply bg-primary-disabled;
  cursor: default;
}

button.btn:disabled {
  cursor: default;
}

.basket-item {
  @apply px-5 py-3 mb-px bg-bluegrey-100 text-grey-600 hover:bg-bluegrey-200;
  .line1,
  .line2 {
    @apply block text-grey-500 font-light;
    label {
      @apply text-bluegrey-800;
      &:not(:first-child) {
        @apply ml-1;
        &::before {
          content: '|';
          @apply mr-1;
        }
      }
    }
  }
  .line1 {
    @apply text-grey-800;
    strong {
      @apply font-medium text-bluegrey-800;
    }
  }
}

@mixin textsm {
  @apply text-sm;
}

// used to hide zendesk launcher As currently zendesk doesn't support this out of the box.
.ze-hide iframe[title^='Button to launch messaging window'],
.ze-hide iframe[title^='Number of unread messages'] {
  display: none !important;
}
